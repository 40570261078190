body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.App {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

textarea, input[type="text"], input[type="number"], input[type="date"], input[type="email"] {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item input {
  flex: 1;
  margin: 0 5px;
}

.item button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.item button:hover {
  background-color: #ff1a1a;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

button:hover {
  background-color: #0056b3;
}

.summary {
  text-align: right;
}

.summary p {
  margin: 5px 0;
}

@media only screen and (min-width: 600px){
.section.business {
  width: 48%;
  padding: 20px;
}

.section.client {
  width: 48%;
  padding: 20px;
}

.header-part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoice-part{
  display: flex;
  justify-content: center;
  align-items: center;
}
.section.details {
  width: 28%;
  padding: 20px;
}
input.tax0d {
  width: 15% !important;
}
.section.items {
  width: 68%;
  padding: 20px;
}
}